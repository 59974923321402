import PartnerBanner from "./partnerBanner";

const Hero = () => {

  return (
    // mt-[-3rem]
    <>
    <div className="bg-home md:mt-[-3rem] relative w-full">
      <br/> <br/> <br/>  <br/>  <br/> <br/>
    <PartnerBanner/>
      <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="flex flex-col sm:flex-row items-center lg:gap-[3rem] mt-[1rem] pt-[4rem] md:pt-[9.62rem]">
          <div className="md:w-1/2 md:block flex flex-col items-center justify-center text-center md:text-left mb-8">
            <h1 className="text-[2rem] xl:text-[3rem] text-[#DFDFDF] lg:text-left font-[700] lg:w-[31.3125rem]">
            Reliable and Fast Delivery Services You Can Trust
            </h1>
            <p className="text-[#D3D3D3] text-[1.125rem] xl:text-[1.4375rem] font-normal lg:w-[30.4375rem] pt-[1rem] pb-[2rem] md:py-[1rem] lg:pt-[1.69rem] lg:pb-[1.87rem]">
            Whether you're sending important documents, gifts, or bulk shipments, we have the expertise and commitment to handle <span className="font-bold text-[#ffffff]">all your delivery needs with precision and care.</span>
             </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Hero;


