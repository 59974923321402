import LegalParent from "./legalParent";

interface IPP {}

const PrivacyPolicy = (props: IPP) => {
    return (
        <div>
            <LegalParent title="Privacy Policy" date="Last Updated: July 27, 2023" page="">
                <section className="text-[12px] leading-8">
                    <div className="hidden">
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Privacy Notice</p>
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Last Updated: July 27, 2023</p>
                    </div>
                    {/* Privacy Policy */}
                    <div>
                        <b className="text-[1rem] text-left">Privacy Policy</b>
                        <p>
                            Obic Logistics is committed to protecting the privacy of our customers. This privacy policy outlines how we use your personal information when you use our services.
                        </p>
                    </div>

                    {/* Information We Collect */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>1.</b>
                        <div>
                            <b>Information We Collect</b>
                            <ul className="ml-[1rem]">
                                <li>Personal Identification Information: Name, address, phone number, email address, and any other contact details you provide.</li>
                                <li>Transaction Information: Details about payments you make, including payment method and related financial information.</li>
                                <li>Delivery Information: Information related to your parcels, including pickup and delivery addresses, contents, and recipient details.</li>
                            </ul>
                        </div>
                    </div>

                    {/* How We Use Your Information */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>2.</b>
                        <div>
                            <b>How We Use Your Information</b>
                            <ul className="ml-[1rem]">
                                <li>Service Delivery: To process and deliver your orders, including scheduling pickups, managing logistics, and ensuring successful delivery.</li>
                                <li>Customer Support: To provide you with support, respond to your inquiries, and handle complaints or issues.</li>
                                <li>Payment Processing: To process payments for services rendered and issue refunds where applicable.</li>
                                <li>Improvement of Services: To analyze how our services are used, improve our offerings, and develop new services.</li>
                                <li>Marketing: With your consent, to send you promotional materials and offers that may interest you. You can opt-out of marketing communications at any time.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </LegalParent>
        </div>
    );
}

export default PrivacyPolicy;
