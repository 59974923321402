import {createBrowserRouter} from "react-router-dom"
import Home from "./pages/home"
import FAQs from "./pages/faq"
import WebLayout from "./components/Layout/WebLayout"
import Partnership from "./pages/partnership"
import PrivacyPolicy from "./pages/legal/privacy"
import TermsOfService from "./pages/legal/terms"
import Company from "./pages/company"
import Services from "./pages/services"
import Pricing from "./pages/pricing"

const routes = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <WebLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "faq",
          element: <FAQs />,
        },
        {
          path: "partnership",
          element: <Partnership />,
        },
        {
          path: "terms-of-service",
          element: <TermsOfService />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "company",
          element: <Company />,
        },
        {
          path: "career",
          element: <Company/>,
        },
        {
          path: "services",
          element: <Services/>
        },
        {
          path: "pricing",
          element: <Pricing/>,
        },
      ],
    },
  ])
}

export default routes
