export const jobDetails = [
  {
    jobTitle: "Truck Driver",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "truck-driver",
    slug: "truck-driver",
    dept: "Logistics",
    jobLink: "https://example.com/truck-driver-application",
    description: `
  ## What you'll do

  As a Truck Driver, you'll be responsible for transporting goods safely and efficiently from our warehouses to various locations. You will ensure that goods arrive on time and in perfect condition. You will also be required to maintain the truck in good working order and follow all traffic regulations.

  - Transport goods safely and on time.
  - Ensure goods are properly loaded and secured.
  - Keep the truck clean and in good condition.
  - Report any issues with the vehicle.
  - Comply with road and traffic regulations.

  ## Who you are

  ##### Minimum requirements

  - Valid commercial driving license.
  - 2+ years of experience as a truck driver.
  - Excellent knowledge of Delta routes.
  - Ability to work independently.
  - Strong communication skills.
  - Must be physically fit to handle long trips.
  `
  },
  {
    jobTitle: "Bus Driver",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "bus-driver",
    slug: "bus-driver",
    dept: "Logistics",
    jobLink: "https://example.com/bus-driver-application",
    description: `
  ## What you'll do

  As a Bus Driver, you'll be responsible for transporting passengers safely to various destinations. You’ll also ensure that the bus is in good working condition and report any issues.

  - Transport passengers safely and courteously.
  - Follow assigned routes and schedules.
  - Ensure the bus is clean and well-maintained.
  - Report any mechanical issues.
  - Ensure passenger safety and assist with boarding when necessary.

  ## Who you are

  ##### Minimum requirements

  - Valid commercial driving license.
  - 2+ years of experience as a bus driver.
  - Familiarity with Delta road networks.
  - Strong customer service skills.
  - Ability to work flexible hours.
  `
  },
  {
    jobTitle: "Dispatch Rider",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "dispatch-rider",
    slug: "dispatch-rider",
    dept: "Logistics",
    jobLink: "https://example.com/dispatch-rider-application",
    description: `
  ## What you'll do

  As a Dispatch Rider, you will deliver packages to customers promptly and safely. You'll manage a variety of deliveries and ensure proper documentation.

  - Safely deliver packages to customers.
  - Maintain accurate delivery records.
  - Plan routes to ensure timely delivery.
  - Report any delivery issues or delays.
  - Maintain the motorcycle in good condition.

  ## Who you are

  ##### Minimum requirements

  - Valid rider's permit.
  - 1+ years of experience as a dispatch rider.
  - Familiarity with Delta routes.
  - Ability to work independently.
  - Good customer service and communication skills.
  `
  },
  {
    jobTitle: "Sales Personnel",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "sales-personnel",
    slug: "sales-personnel",
    dept: "Sales",
    jobLink: "https://example.com/sales-personnel-application",
    description: `
  ## What you'll do

  As a Sales Personnel, you’ll be responsible for generating sales leads and building strong relationships with customers. You will also ensure that sales targets are met and customer satisfaction is achieved.

  - Generate sales leads and follow up with customers.
  - Build and maintain relationships with new and existing clients.
  - Achieve sales targets and quotas.
  - Provide product information and resolve customer inquiries.
  - Collaborate with the marketing team to optimize sales strategies.

  ## Who you are

  ##### Minimum requirements

  - Bachelor's degree in Marketing, Business, or a related field.
  - 2+ years of experience in sales.
  - Excellent communication and negotiation skills.
  - Ability to work in a fast-paced environment.
  - Strong customer service orientation.
  `
  },
  {
    jobTitle: "Marketer",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "marketer",
    slug: "marketer",
    dept: "Marketing",
    jobLink: "https://example.com/marketer-application",
    description: `
  ## What you'll do

  As a Marketer, you'll be responsible for promoting our services and products to potential clients. You will also collaborate with the sales team to develop marketing strategies and campaigns.

  - Develop and execute marketing campaigns.
  - Identify target markets and potential customers.
  - Monitor market trends and adjust strategies accordingly.
  - Collaborate with the sales team to align marketing efforts.
  - Create promotional materials and engage with customers.

  ## Who you are

  ##### Minimum requirements

  - Bachelor's degree in Marketing or related field.
  - 2+ years of marketing experience.
  - Excellent written and verbal communication skills.
  - Ability to think creatively and strategically.
  - Experience in digital marketing and social media platforms.
  `
  },
  {
    jobTitle: "Business Agent",
    jobType: "Full-time",
    location: "Delta",
    link: "#",
    index: "business-agent",
    slug: "business-agent",
    dept: "Business Development",
    jobLink: "https://example.com/business-agent-application",
    description: `
  ## What you'll do

  As a Business Agent, you'll identify business opportunities and assist in the expansion of our services. You will collaborate with internal teams to support business growth strategies.

  - Identify and develop new business opportunities.
  - Build relationships with key decision-makers.
  - Support business expansion initiatives.
  - Collaborate with internal teams to meet business targets.
  - Conduct market research and provide feedback to management.

  ## Who you are

  ##### Minimum requirements

  - Bachelor's degree in Business, Marketing, or a related field.
  - 2+ years of experience in business development or sales.
  - Strong negotiation and presentation skills.
  - Ability to work independently and meet targets.
  - Knowledge of market research and analysis.
  `
  }
];
