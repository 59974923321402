export default function Partnership() {
    return (
        <>
            <div className="bg-[#FAFAFA]">
                <main className="w-[90%] md:w-[80%] xl:w-[1024px] mx-auto pt-40 pb-8">
                    <h1 className="text-[#000000] text-2xl xl:text-4xl pb-[2.14rem] font-bold">
                        Enhance Your Brand with Our Strategic Logistics Partnership
                    </h1>
                    <div className="pt-6">
                        <article className="text-black font-normal text-lg leading-[2.75rem]">
                            Partnering with a reputable logistics company like ours is more than just a service agreement; it’s a strategic move to elevate your brand and streamline your operations. Here’s how you can benefit from this collaboration:

                            <h3 className="text-2xl font-bold my-12">Benefits of Partnering with Us:</h3>

                            <ol className="list-decimal ml-4">
                                <li className="font-bold">Enhanced Brand Image:</li>
                                <ul className="list-disc ml-10">
                                    <li className="text-lg leading-[2.75rem]">
                                        By partnering with us, you align your business with a company dedicated to excellence. This will reflect positively on your brand, enhancing your reputation and fostering trust among your customers.
                                    </li>
                                </ul>

                                <li className="font-bold mt-4">Dedicated Customer Support:</li>
                                <ul className="list-disc ml-10">
                                    <li className="text-lg leading-[2.75rem]">
                                        Our dedicated support team is here to ensure a seamless experience for both your business and your customers. We promptly address inquiries and concerns, helping maintain smooth operations and high satisfaction levels.
                                    </li>
                                </ul>

                                <li className="font-bold mt-4">Proposed Collaboration:</li>
                                <ul className="list-disc ml-10">
                                    <li className="text-lg leading-[2.75rem]">
                                        We propose becoming your official dispatch company, taking care of all your delivery needs efficiently and effectively. In return, we offer discounted collaboration prices and high-quality promotional videos, produced at no additional cost, to highlight our partnership and promote your brand.
                                    </li>
                                </ul>
                            </ol>

                            <div className="mt-20">
                                <span className="font-bold text-xl">Next Steps:</span>
                                <ul className="list-disc ml-10">
                                    <li className="text-lg leading-[2.75rem]">
                                        We’d love to discuss this opportunity further and explore how we can tailor our services to meet your unique needs. Please feel free to contact us at 08144197850 to schedule a meeting at your convenience.
                                    </li>
                                </ul>
                            </div>

                            <p className="py-20">
                                Thank you for considering this collaboration proposal. We look forward to the possibility of working together and contributing to the success of your business.
                            </p>
                        </article>
                    </div>
                </main>
            </div>
        </>
    );
}
