import DetailSection from "./Detail";
import FeaturesSection from "./features";
import Hero from "./hero";
import Products from "./products";

function HomePage() {
    return (
      <>
        <Hero/>
        <Products/>
        <FeaturesSection/>
        <DetailSection/>
    </>
    )
}

export default HomePage;