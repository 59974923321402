import React from 'react';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import { FaSquareTwitter } from 'react-icons/fa6';

const DetailSection = () => {
  return (
    <section className="bg-deep-blue">
      <div className="justify-center text-center center-box p-5">
        <div className='justify-center flex m-auto'>
          <img src="/hero.png" width="190" alt="" />
        </div>
        <h1 className="text-white"><b>Seamless and dependable services for your delivery needs</b></h1>
        <h5 className='mt-5'>
          <small style={{ lineHeight: '125%', color: 'white' }}>
          we go the extra mile to ensure your satisfaction. Trust us with your delivery needs and experience the difference of a dedicated delivery partner. Contact us today to learn more about our services or to schedule a delivery
          </small>
        </h5>
      
        <div className="justify-center">
        <div className="social-links mt-3 flex justify-center">
                <a href="https://twitter.com/obic" className="mx-2"> <FaSquareTwitter style={{ color: 'orange' }} /></a>
                <a href="https://www.instagram.com/obiclogistics?igsh=MTV5bWRlMjFwMnpmNg%3D%3D&utm_source=qr" className="mx-2"> <FaInstagram style={{ color: 'orange' }} /></a>
                <a href="https://www.tiktok.com/@obic_ng?_t=8pU2ww4m77t&_r=1" className="mx-2"><FaTiktok style={{ color: 'orange' }} /></a>
        </div>
        </div>
        <div className="mx-auto d-flex justify-content-center mt-8">
        <a href="https://wa.me/8135698995" target="_blank" rel="noopener noreferrer">
                 <button className="bg-orange px-4 py-2 no-border rounded-lg text-white font-semibold">
                Book Now
              </button>
        </a>
        </div>
        {/* <div className="mt-5 col-md-5 mx-auto d-flex justify-content-center">
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/google-white.svg" width={150} alt="Google Play" />
                </a>
                <a
                  href="https://api.obictrade.com/download"
                  className="w-auto mx-2"
                >
                  <img src="/apple-white.svg" width={150} alt="Apple Store" />
                </a>
              </div> */}

      </div>
      <br />
    </section>
  );
};

export default DetailSection;
