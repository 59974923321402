import ServiceHeader from "./serviceHeader"
import Benefits from "./benefits"
import Filler from "./filler";
import {useEffect} from "react"
import PartnerBanner from "../home/partnerBanner";

const Services = () => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  }, [])
  return (
    <>
      <ServiceHeader />
      <Benefits />
      <Filler />
      <PartnerBanner/>
    </>
  )
}

export default Services
