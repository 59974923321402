import Button from '../../components/Button';

const Benefits = () => {

  return (
    <div>
      <div className="w-[90%] xl:w-[75%] mx-auto 2xl:w-[1280px] py-8 md:py-16">
        <section className='grid gap-3 lg:gap-[2rem] xl:gap-[3.75rem]'>
          <article className='gradient-bg pb-2 p-[1.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
            <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:pb-8 lg:pb-0">
              <div className='text-white'>
                <h3 className='font-bold text-2xl lg:text-[1.75rem] lg:leading-10'>
                  Exceptional Delivery Services, Every Time
                </h3>
                <p className='text-sm md:text-lg pt-2 pb-[2.44rem] leading-6'>
                  Benefit from our wide range of services designed to meet your personal and business delivery needs.
                </p>
                <Button
                  text="Discover More"
                  fn={() => window.open("https://wa.me/8135698995")}
                  style={`px-[2rem] rounded-[0.75rem] bg-white`}
                />
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
}

export default Benefits;
