import React from 'react';
import { FaLock, FaTruck, FaCog } from 'react-icons/fa'; // Importing icons

const FeaturesSection = () => {
  return (
    <section className="bg-blue-900">
      <div className="text-center p-12">
        <div className="flex flex-wrap justify-center">
          
          <div className="w-full sm:w-1/2 lg:w-1/4 p-6 flex flex-col items-center">
            <div className="text-white mb-6 flex items-center justify-center">
              <FaLock size={60} />
            </div>
            <h3 className="text-white font-bold mb-4">Dependable and Secure</h3>
            <p className="text-white">At Obic Delivery, your trust matters. We safeguard your deliveries with top-notch security, giving you peace of mind every step of the way.</p> 
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/4 p-6 flex flex-col items-center">
            <div className="text-white mb-6 flex items-center justify-center">
              <FaTruck size={60} />
            </div>
            <h3 className="text-white font-bold mb-4">Hassle-Free Service</h3>
            <p className="text-white">Obic Delivery is all about simplicity. We’ve streamlined our services to ensure your deliveries are handled effortlessly and efficiently.</p>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/4 p-6 flex flex-col items-center">
            <div className="text-white mb-6 flex items-center justify-center">
              <FaCog size={60} />
            </div>
            <h3 className="text-white font-bold mb-4">Designed for Your Convenience</h3>
            <p className="text-white">We’ve tailored Obic Delivery to fit your unique needs. Experience a service that’s thoughtfully crafted to give you exactly what you’re looking for.</p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
