import { useState } from "react";
import { Dialog, Disclosure } from "@headlessui/react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link } from "react-router-dom";

function MobileMenu({ setMobileMenuOpen }: any) {
  return (
    <>
      <Dialog.Panel className="fixed inset-y-0 right-0 z-1000 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm">
        <div className="flex items-center justify-between">
          <a href="/">
            <img src="/header2.png" alt="Obic Logo" width={260} />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <IoMdClose className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="mt-6 flow-root z-1000" onClick={() => setMobileMenuOpen(false)}>
          <div className="-my-6 divide-y divide-gray-500/10 pt-12">
            <div className="space-y-4 py-6">
              <Link to="/" className="block text-lg font-semibold text-gray-900">
                HOME
              </Link>
              <Link to="/services" className="block text-lg font-semibold text-gray-900">
                SERVICES
              </Link>
              <Link to="/pricing" className="block text-lg font-semibold text-gray-900">
                PRICING
              </Link>
              <Link to="/faq" className="block text-lg font-semibold text-gray-900">
                FAQ
              </Link>
              <Link to="/company" className="block text-lg font-semibold text-gray-900">
                COMPANY
              </Link>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </>
  );
}

function Banner() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const linkStyle =
    "text-[#000000] text-base font-normal font-medium leading-6 transition-all duration-300 hover:text-cleva-gold hover:underline";

  return (
    <>
      <nav className="bg-white fixed z-50 w-full border-b border-gray-200 top-0" aria-label="Global">
       {!mobileMenuOpen &&
        <div className="flex justify-between items-center py-4 w-[90%] min-[1350px]:w-[80%] mx-auto 2xl:w-[1280px]">
          <a href="/">
            <img src="/header2.png" alt="Obic Logo" height={30} width={260} />
          </a>

          <div className="lg:hidden">
            <button
              type="button"
              className="p-2 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <IoMdMenu className="h-8 w-8" />
            </button>
          </div>

          <div className="hidden lg:block">
            <ul className="text-[22px] flex flex-row items-center justify-center space-x-10">
              <Link to="/" className={linkStyle}>
                HOME
              </Link>
              <Link to="/services" className={linkStyle}>
                SERVICES
              </Link>
              <Link to="/pricing" className={linkStyle}>
                PRICING
              </Link>
              <Link to="/faq" className={linkStyle}>
                FAQ
              </Link>
              <Link to="/company" className={linkStyle}>
                COMPANY
              </Link>
            </ul>
          </div>
        </div>
}
        <Dialog
          as="div"
          className="lg:hidden z-1000"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-100" />
          <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />
        </Dialog>
      </nav>
    </>
  );
}

export default Banner;
