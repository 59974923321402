import Banner from '../Banner';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';

export default function WebLayout() {
    return (
        <div className='font-montserrat'>
            <Banner/>
            <div>
               <Outlet/>
            </div>
            <div className=''>
                <Footer />
            </div>
        </div>
    )
}

//faq