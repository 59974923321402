import { Link } from "react-router-dom";
import { useEffect } from "react";
import img1 from '../../images/sections/1.jpeg';
import img2 from '../../images/sections/2.jpeg';
import img3 from '../../images/sections/3.jpeg';

interface IProducts {
    innerRef?: React.RefObject<HTMLDivElement>;
}

const Products = ({ innerRef }: IProducts) => {

    const headingStyle = "text-[#060606] font-bold";
    const contentStyle = "text-[#060606] text-base xl:text-[1.125rem] font-normal leading-[1.75rem] pt-[1rem] py-[1.5rem] xl:pt-[1.12rem] xl:pb-[2.5rem]";

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <section ref={innerRef} className="">
            <div className="w-[80%] xl:w-[100%] md:p-8 mx-auto 2xl:w-[80%] py-[2.88rem]">
                {/* Fast Delivery Section */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-[4.79rem] lg:gap-y-[0] gap-y-[2.5rem] p-5">
                    <div>
                        <p className={`${headingStyle} text-[1.375rem] xl:text-[1.75rem] lg:w-[28rem]`}>
                            Fast and Reliable Delivery
                        </p>
                        <p className={`${contentStyle} lg:w-[24rem]`}>
                            Obic Logistics ensures your packages are delivered quickly and safely, whether it's across town or across the country.
                        </p>
                        <Link to="https://wa.me/8135698995" className="border border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium px-[.75rem] py-[.5rem] rounded-lg">
                            Learn More
                        </Link>
                    </div>
                    <div className="min-[1440px]:flex-shrink-0">
                        <img src={img1} alt={img1} width={600} />
                    </div>
                </div>

                {/* Seamless Shipping Section */}
                <div className="flex justify-between flex-col md:flex-row items-center xl:gap-x-[8rem] gap-x-[4rem]">
                    <div className="xl:flex-shrink-0 order-2 md:order-1 relative pt-[3rem]">
                        <img src={img2} alt={img2} width={600} />
                    </div>
                    <div className="order-1 md:order-2">
                        <p className={`${headingStyle} lg:w-[28.5rem] text-[1.375rem] xl:text-[2.25rem]`}>
                            Seamless Shipping Solutions
                        </p>
                        <p className={`${contentStyle} lg:w-[25rem]`}>
                            From local deliveries to international shipments, Obic Logistics provides seamless and stress-free shipping solutions.
                        </p>
                        <Link to="https://wa.me/8135698995" className="border border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium px-[.75rem] py-[.5rem] rounded-lg">
                            Learn More
                        </Link>
                    </div>
                </div>

                {/* Global Reach Section */}
                <div className="flex justify-between flex-col md:flex-row items-center mt-[4.79rem] lg:gap-y-[0] gap-y-[2.5rem]">
                    <div>
                        <p className={`${headingStyle} lg:w-[26.63875rem] xl:w-[33rem] text-[1.375rem] xl:text-[1.75rem]`}>
                            Expanding Your Global Reach
                        </p>
                        <p className={`${contentStyle} lg:w-[28rem]`}>
                            With Obic Logistics, you can expand your business’s reach with reliable international shipping and logistics solutions.
                        </p>
                        <Link to="https://wa.me/8135698995" className="border border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium px-[.75rem] py-[.5rem] rounded-lg">
                            Learn More
                        </Link>
                    </div>
                    <div className="text-left min-[1440px]:flex-shrink-0">      
                        <img src={img3} alt={img3} width={600} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Products;
