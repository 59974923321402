import LegalParent from "./legalParent";

interface TOS { }

const TermsOfService = (props: TOS) => {
    return (
        <div>
            <LegalParent title="Terms and Conditions" date="Last Updated: July 27, 2023" page="">
                <section className="text-[12px] leading-8">
                    <div className="hidden">
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Terms and Conditions</p>
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Last Updated: July 27, 2023</p>
                    </div>

                    {/* 1. Introduction */}
                    <div className="flex gap-8">
                        <b>1.</b>
                        <div>
                            <b>Introduction</b>
                            <p>
                                Welcome to Obic Logistics. By using our website and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you should not use our services.
                            </p>
                        </div>
                    </div>

                    {/* 2. Services */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>2.</b>
                        <div>
                            <b>Services</b>
                            <p>
                                We offer logistics and dispatch services throughout Asaba, including but not limited to errands, food deliveries, grocery pickups, and logistics. Our services are subject to availability and may be modified.
                            </p>
                        </div>
                    </div>

                    {/* 3. User Responsibilities */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>3.</b>
                        <div>
                            <b>User Responsibilities</b>
                            <ul className="ml-[1rem]">
                                <li><b>Accuracy of Information:</b> You are responsible for providing accurate information, including pickup and delivery addresses, contact information, and payment details.</li>
                                <li><b>Compliance with Laws:</b> You agree to comply with all applicable Nigerian laws and regulations when using our services.</li>
                                <li><b>Prohibited Items:</b> Certain items are prohibited from our services, including but not limited to hazardous materials, illegal substances, and certain perishable goods.</li>
                            </ul>
                        </div>
                    </div>

                    {/* 4. Booking and Payment */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>4.</b>
                        <div>
                            <b>Booking and Payment</b>
                            <ul className="ml-[1rem]">
                                <li><b>Booking Confirmation:</b> Once you place a booking, you will receive a confirmation message with the details of your order.</li>
                                <li><b>Payment Terms:</b> Payment for services must be made in full before delivery, unless otherwise agreed. We accept cash, bank transfers, and mobile payments (such as via POS or mobile banking apps).</li>
                                <li><b>Cancellation:</b> Cancellations may be subject to a fee.</li>
                            </ul>
                        </div>
                    </div>

                    {/* 5. Delivery */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>5.</b>
                        <div>
                            <b>Delivery</b>
                            <ul className="ml-[1rem]">
                                <li><b>Delivery Time:</b> We will make every effort to deliver your items within the estimated timeframe, but delays may occur due to unforeseen circumstances, including traffic, weather conditions, or other factors.</li>
                                <li><b>Failed Deliveries:</b> If we are unable to deliver your item due to incorrect address information or the recipient being unavailable, additional charges may apply for re-delivery attempts.</li>
                            </ul>
                        </div>
                    </div>

                    {/* 6. Liability */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>6.</b>
                        <div>
                            <b>Liability</b>
                            <p>
                                <b>Limitations of Liability:</b> Our liability for any loss or damage arising from the use of our services is limited to the value of the goods being transported. We are not liable for any indirect or consequential losses.
                            </p>
                        </div>
                    </div>

                    {/* 7. Amendments */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>7.</b>
                        <div>
                            <b>Amendments</b>
                            <p>
                                We reserve the right to amend these terms and conditions at any time. Changes will be effective immediately upon posting on our website. Continued use of our services after any changes constitutes your acceptance of the new terms.
                            </p>
                        </div>
                    </div>

                    {/* 8. Governing Law */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>8.</b>
                        <div>
                            <b>Governing Law</b>
                            <p>
                                These terms and conditions are governed by and construed in accordance with the laws of the Federal Republic of Nigeria. Any disputes arising under or in connection with these terms will be subject to the exclusive jurisdiction of the courts in Delta State, Nigeria.
                            </p>
                        </div>
                    </div>

                    {/* 9. Contact Information */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>9.</b>
                        <div>
                            <b>Contact Information</b>
                            <p>
                                If you have any questions or concerns about these terms, please contact us at
                                <ul className="ml-[1rem]">
                                    <li><a href="mailto:obictrade@hotmail.com" className="text-sky-500">obictrade@hotmail.com</a></li>
                                    <li>08144197850 / 08135698995</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    {/* 10. Privacy Policy */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>10.</b>
                        <div>
                            <b>Privacy Policy</b>
                            <p>
                                Obic Logistics is committed to protecting the privacy of our customers. The privacy policy states how we use your personal information when you use our services.
                            </p>
                            <ul className="ml-[1rem]">
                                <li><b>Information We Collect:</b> We may collect and process the following types of personal information:
                                    <ul className="ml-[1rem]">
                                        <li>Personal Identification Information: Name, address, phone number, email address, and any other contact details you provide.</li>
                                        <li>Transaction Information: Details about payments you make, including payment method and related financial information.</li>
                                        <li>Delivery Information: Information related to your parcels, including pickup and delivery addresses, contents, and recipient details.</li>
                                    </ul>
                                </li>
                                <li><b>How We Use Your Information:</b> We use your personal information for the following purposes:
                                    <ul className="ml-[1rem]">
                                        <li>Service Delivery: To process and deliver your orders, including scheduling pickups, managing logistics, and ensuring successful delivery.</li>
                                        <li>Customer Support: To provide you with support, respond to your inquiries, and handle complaints or issues.</li>
                                        <li>Payment Processing: To process payments for services rendered and issue refunds where applicable.</li>
                                        <li>Improvement of Services: To analyze how our services are used, improve our offerings, and develop new services.</li>
                                        <li>Marketing: With your consent, to send you promotional materials and offers that may interest you. You can opt-out of marketing communications at any time.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </LegalParent>
        </div>
    );
}

export default TermsOfService;
