const faq = [
  {
    question: "What areas does Obic Logistics serve?",
    answer: `Obic Logistics primarily serves Asaba, Delta State, and its surrounding areas including Ibusa, Ogwash, Ugbolu, Issele Asagba, etc.`,
  },
  {
    question: "What services does Obic Logistics provide?",
    answer: `We specialize in same-day delivery, express delivery, and scheduled deliveries for businesses and individuals. Our services include parcel delivery, document delivery, and goods transportation within Asaba and beyond.`,
  },
  {
    question: "How can I book a delivery with Obic Logistics?",
    answer: `You can book a delivery through our website, by calling our customer service, or by sending a message to our WhatsApp representative. Simply provide the details of your package and the delivery destination, and we’ll take care of the rest.`,
  },
  {
    question: "What are your delivery hours?",
    answer: `Our delivery services operate Monday to Saturday from 8:00 AM to 6:00 PM. We also offer special arrangements for deliveries outside these hours upon request.`,
  },
  {
    question: "What types of items can you deliver?",
    answer: `We deliver a wide range of items, including documents, parcels, groceries, electronics, and more. However, we do not handle hazardous materials, illegal items, or perishable goods without proper packaging.`,
  },
  {
    question: "What happens if my package is delayed?",
    answer: `We strive to ensure timely deliveries. In the rare event of a delay, our customer service team will notify you immediately and provide an updated delivery time.`,
  },
  {
    question: "What are your delivery charges?",
    answer: `Our delivery charges depend on the distance, package size, and type of service selected. You can get an instant quote on our website or by contacting our customer service.`,
  },
  {
    question: "Do you offer discounts for bulk deliveries?",
    answer: `Yes, we offer discounts for bulk deliveries and long-term business partnerships. Please contact our sales team for more information on our pricing plans.`,
  },
  {
    question: "How do I contact Obic Logistics for support?",
    answer: `You can reach us via phone, email, or through our social media channels. Our customer service team is available during business hours to assist with any inquiries or issues you may have.`,
  },
  {
    question: "What payment methods do you accept?",
    answer: `We accept various payment methods, including bank transfers, POS, and cash on delivery.`,
  },
  {
    question: "What measures do you take to ensure the safety of my package?",
    answer: `We prioritize the safety of your package by using secure packaging and trained delivery personnel. Our tracking system also allows us to monitor your package throughout its journey.`,
  },
];

export {
  faq
}
