import './App.css';
import ErrorBoundary from "./ErrorBoundary";
import { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useIntercom } from 'react-use-intercom';

function App() { 

  const { boot } = useIntercom();
  useEffect(() => {
    boot({name: "Buddy"});
  },[boot]);
  
  return (
    <>
      <Suspense
        fallback={
          <div>
          </div>
        }>
            <ErrorBoundary>
              <RouterProvider router={routes()} />
            </ErrorBoundary>
      </Suspense>
    </>
  );
}

export default App;
