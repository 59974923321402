import Button from "../../components/Button";
import { services } from './data';

const Filler = () => {

    return (
        <div className="bg-[#F8FCFF]">
            <div className="w-[90%] xl:w-[80%] mx-auto min-[1440px]:w-[70%] 2xl:w-[1280px] py-8 md:py-16">
                {services.map((service, index) => (
                    <section
                        key={index}
                        className="flex flex-col md:flex-row-reverse items-center justify-center gap-8 lg:gap-[9rem] mb-8"
                    >
                        <div>
                            <h3 className="text-xl lg:text-3xl xl:w-[32rem] font-semibold">
                                {service.title}
                            </h3>
                            <p className="pt-2 pb-4 xl:pb-[2.44rem] xl:w-[28rem] leading-6 text-sm md:text-lg">
                                {service.description}
                            </p>
                            <Button
                                text="Get started"
                                fn={() => window.open("https://wa.me/8135698995")}
                                style={`px-[2rem] py-[0.5rem] rounded-[0.5rem]`}
                            />
                        </div>
                        <img src={service.image} alt="" width={250} />
                    </section>
                ))}
            </div>
        </div>
    );
};

export default Filler;