// import { useLocation } from 'react-router-dom';
import {useEffect, useRef} from "react"
// import CompanyHeader from './companyHeader';
import Vacancies from "./vacancy"
import teamVideo from "../../images/team/team.mov"
import {CompanyMission} from "./companyMission"
import PartnerBanner from "../home/partnerBanner"

const Company = () => {
  const vacancyRef = useRef<HTMLDivElement>(null)
  const vacanciesRef = useRef<HTMLDivElement>(null)
  // const location = useLocation();

  // const scrollToJobs = () => {
  // 	if (vacancyRef?.current) {
  // 		const element = vacancyRef.current;
  //   const offset = element.getBoundingClientRect().top + window.scrollY - 150;
  // window.scrollTo({
  //             top: offset,
  //             behavior: 'smooth'
  //         });

  // 	} else if (location.pathname !== '/careers') {
  // 		window.open('/careers', '_blank')
  // 	}
  // };

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  }, [])

  return (
    <>
      {/* <CompanyHeader scrollToJobs={scrollToJobs} /> */}
      <div className="responsive-layout min-h-screen mb pt-16">
        <br />
        <br />
        <CompanyMission />
        <PartnerBanner />

        <div className="my-8">
          <video
            className="rounded-md h-auto w-screen object-cover"
            src={teamVideo}
            controls
          />
        </div>

        <div ref={vacancyRef}></div>

        <div className="">
          <h2 className="uppercase font-bold text-3xl text-center text-black">
            Careers
          </h2>
          <p className="pt-2 text-center">Come join the team!</p>
        </div>

        <div className="mb-[10rem]">
          <Vacancies innerRef={vacanciesRef} />
        </div>
      </div>
    </>
  )
}

export default Company
