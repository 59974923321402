const Prices = () => {

    return (
        <div className="flex justify-center items-start mb-[4.79rem]">
            {/* Benefits Section */}
            <div className="w-full justify-center mb-4">
                <div className="text-center m-3">
                    {/* <h3 className="md:w-[20rem] m-auto font-bold text-2xl lg:text-[1.75rem]">
                        Benefits of Collaborating with Us
                    </h3> */}
                    <p className="md:w-[20rem] m-auto text-[#555656] text-sm md:text-sm pt-3 pb-[2.44rem]">
                        Explore our cost-effective delivery solutions
                    </p>
                </div>
                <div className="relative flex-1">
                    <div className="md:w-[40rem] m-4 mx-auto px-16 py-10 border border-[#EFEFEF] bg-white rounded-[12px] shadow">
                        {/* Subscription Plans */}
                        <div className="mb-4">
                            <h4 className="text-xl font-bold">Cost-Effective Solutions:</h4>
                            <p className="mt-4">
                                We offer competitive pricing models tailored to meet the specific needs of your business when you subscribe to our monthly bulk delivery plan.
                            </p>
                        </div>

                        <hr className="w-full my-4 px-4" />

                        {/* Price List */}
                        <div>
                            <h4 className="text-xl font-bold">Delivery Price List:</h4>
                            <table className="w-full mt-4">
                                <thead>
                                    <tr className="font-bold">
                                        <th className="text-left">Price (₦)</th>
                                        <th className="text-left">Locations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="py-2">2,500</td>
                                        <td>
                                            Within town, Okpanam Road, Nnebisi Road, Anwai Road, Summit, Koka
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-2">3,000</td>
                                        <td>
                                            Conoil, Ogbeogonogo, Last Redeem, Behind Midwifery, Nwawolor, Ibusa Road
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-2">3,500</td>
                                        <td>
                                            Former Deputy/FRSC, Ezenei/ULO, Mama's Mart/Bovas, Jemok/Dublin, Dennis Osadebe University, Leisure Park, Okpanam-Town (Amachia, Ogbezioma, etc.)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-2">4,000</td>
                                        <td>
                                            Bonsaac, Okwe/Oduke, Cable/Abraka, Rehoboth City, Federal College, Oko Market, Headbridge, Toll Gate, Immigration, Ugbolu, Ibusa Town
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-2">4,500</td>
                                        <td>
                                            Special Delivery: Ogwashi Ukwu, Amirality University, Issele Asagba, Illah
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr className="w-full my-4 px-4" />

                        <div className="mt-4 text-center">
                            <p className="text-md">
                                Choose a plan or location-specific delivery that suits your business needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prices;
