import { useState } from "react";
import React from "react";

interface IFaqData {
    question?: string,
    answer: string,
    onClick?(): (value: string) => void;
}

interface IFaqDataList {
    data: IFaqData[]
}

const Accordion = ({ data }: IFaqDataList) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const targetWords = ['Experts:', 'Easy_to_use:', 'Affordable:', 'Customer-obsessed:', 'ACH_deposit_fee:', 'Wire_deposit_fee:', 'ACH:', 'Wire:', 'Wire_(from_a_US_Bank):', 'International_wire', 'Card_creation_fee:', 'Card_funding_fee:', 'Card_decline_fee:', 'Dispute_fee:', 'International_transaction_fee:'];

    const handleToggle = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const applyBoldStyling = (words: string[], targetWords: string[], index: number): JSX.Element => {
        const isBold = targetWords.includes(words[0])

        return (
            <span key={index} className={isBold ? 'font-bold' : ''}>
                {words.map((word, index, array) => {
                    const isLink = word.toLowerCase() === 'expansion@getcleva.com';
                    return isLink ? (
                        <a key={index} href="mailtto:expansion@getcleva.com " className="text-blue-600 visited:text-purple-600">
                            {word} </a>
                    ) : (
                        <React.Fragment key={index}>{words.join(' ').replace(/_/g, ' ')} </React.Fragment>

                    );
                })}
            </span>
        );
    };



    return (
        <div className="grid gap-[1rem]" >
            {data.map((item: IFaqData, index: number) => {
                const isActive = activeIndex === index;
                return (
                    <div key={index} className={`rounded-xl border-[#B6B6B6] border-[0.5px] bg-[#F8F8F8] p-6 ${isActive ? 'mb-[2rem]' : 'mb-0'}  transition-max-height duration-300 ease-in-out overflow-hidden`}>
                        <header onClick={() => handleToggle(index)} className="cursor-pointer flex items-center justify-between">
                            <h1 className="color-[#060606] text-[0.875rem]text-xl font-semibold">{item.question}</h1>
                        </header>
                        {isActive &&
                            <div className={`pt-[1rem] transition-opacity duration-300 ease-in-out`}>
                                <p className="color-[#000] text-base font-normal whitespace-pre-line min-h-auto max-h-[50vh] sm:h-auto sm:overflow-visible overflow-scroll">
                                    {item.answer.split(' ').map((word, index, array) => (
                                        applyBoldStyling(array.slice(index, index + 1), targetWords, index)
                                    ))}
                                </p>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default Accordion;