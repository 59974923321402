import { PricingHeader } from "./pricingHeader";
import Prices from "./prices";
import { useEffect } from "react";

const Pricing = () => {

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}, [])

  return (
    <>
      <PricingHeader />
      <div className="bg-[#FBFBFB] p-2">
        <div className="mt-4">
            <Prices/>
        </div>
      </div>
    </>
  );
};

export default Pricing;