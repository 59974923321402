import {  createSlice } from "@reduxjs/toolkit";
interface PricingState {
    Delivery:{
        creation: number,
        decline: number,
        dispute: number,
        international: number,

    },
}

const initialState: PricingState = {
    Delivery : {
        creation: 3,
        decline: 0.30,
        dispute: 50,
        international: 3
    }
};

const PricingSlice = createSlice({
  name: "Pricing",
  initialState,
  reducers: {
  },
});

export default PricingSlice.reducer;