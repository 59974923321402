import { LuDot } from 'react-icons/lu';
// import { GoArrowUpRight } from 'react-icons/go';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { jobDetails } from './data';

interface IVacancy {
	innerRef?: React.RefObject<HTMLDivElement>;
}

const Vacancy = ({ innerRef }: IVacancy) => {
	const navigate = useNavigate(); // Initialize useNavigate

	return (
		<div className="" ref={innerRef}>
			{jobDetails.map((job) => (
				<JobCard
					key={job.index}
					jobTitle={job.jobTitle}
					jobType={job.jobType}
					location={job.location}
					index={job.index}
					description={job.description}
					dept={job.dept}
				/>
			))}
		</div>
	);
};

interface iJobCard {
	jobTitle: string;
	jobType: string;
	location: string;
	index: string;
	description: string;
	dept: string;
}

export const JobCard = ({ jobTitle, jobType, location, index, description, dept }: iJobCard) => {
	const [active, setActive] = useState<string | null>(null);

	return (
		<>
			<div className="cursor-pointer my-8">
				<div className="border shadow-xl bg-[#f5f5f5] p-4">
					<div className="flex justify-between items-center">
						<div>
							<h2 className="text-black font-bold text-xl pb-2">{jobTitle}</h2>
							<div className="text-[.75rem] text-black min-[425px]:text-base min-[375px]:flex items-center">
								<span>{dept}</span>
								<LuDot />
								{jobType} <LuDot />
								<span>{location}</span>
							</div>
						</div>
						{/* <a href={link} className="cursor-pointer flex items-center gap-2">
							<span className="hover:underline font-bold">Apply</span>
							<button className="text-white bg-black rounded-sm text-xl">
								<GoArrowUpRight />{' '}
							</button>
						</a> */}
					</div>
					<section className={`${active === index ? 'block' : 'hidden'}`}>
						<div className="w-full border mt-3"></div>
						<div className="job-description pt-3" dangerouslySetInnerHTML={{ __html: description }} />
					</section>
				</div>
			</div>
		</>
	);
};

export default Vacancy;
