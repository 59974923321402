import service1 from "../../images/services/1.jpeg";
import service2 from "../../images/services/2.jpeg";
import service3 from "../../images/services/3.jpeg";
import service4 from "../../images/services/4.jpeg";
import service5 from "../../images/services/5.jpeg";
import service6 from "../../images/services/6.jpeg";

export const services = [
    {
        title: "Pickup & Delivery",
        description: "Reliable pickup and delivery services for your parcels and packages.",
        image: service1
    },
    {
        title: "Door-to-Door Services",
        description: "Convenient door-to-door delivery right to your doorstep.",
        image: service2
    },
    {
        title: "Errand Services",
        description: "We run errands for you, saving you time and effort.",
        image: service3
    },
    {
        title: "Corporate Delivery",
        description: "Professional delivery services tailored for businesses.",
        image: service4
    },
    {
        title: "Restaurant/Bar Pickups",
        description: "Fast and efficient pickup services from your favorite restaurants and bars.",
        image: service5
    },
    // {
    //     title: "General Delivery Services",
    //     description: "Versatile delivery solutions for all your needs.",
    //     image: service6
    // },
];