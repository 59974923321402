import { Image } from "react-bootstrap";
import { FaTiktok } from "react-icons/fa";
import { FaSquareTwitter, FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const headerStyle = "font-semibold text-[1.25rem] pb-[.5rem]";
  const contentStyle = "text-[1rem] font-normal";
  const hoverStyle =
    "transition duration-500 hover:text-cleva-gold cursor-pointer";
  const YEAR = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <section className="bg-[#ffffff] text-dark">
      <div className="py-[3.06rem] w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="flex flex-col  min-[1500px]:flex-row gap-[3.3rem] min-[1500px]:gap-[7.44rem] pb-[4rem]">
          <button onClick={scrollToTop} className="flex ">
            <Image className="" src={'/obic.png'} alt="Cleva Logo" width={100}/>
          </button>

          <div className="flex flex-col lg:flex-row gap-[3.19rem] lg:gap-x-[4.56rem]">
            <div className="">
              <h5 className={`${headerStyle}`}>Locations:</h5>
              <ul className="grid gap-[0.5rem]">
                <li className={`${contentStyle}`}>
                  <b>Head Office Address: </b> Ezeobi Ujukwu street, opposite Marble hill junction, Asaba, Delta State.
                 </li>
                 <li className={`${contentStyle}`}>
                  <b>Branch Office Address: </b> No 18 Ifechukwukwu plaza along Ugbolu/Akwaukwu-igbo road, Delta State.
                 </li>
   
              </ul>
            </div>
            {/* company */}
            <div className="">
              <h5 className={`${headerStyle}`}>Company:</h5>
              <div className="grid gap-[0.5rem]">
                <Link to="/services">
                <a className={`${hoverStyle}  font-normal text-base   cursor-pointer`}>Services</a> 
                </Link>
                <Link to="/pricing">
                <a className={`${hoverStyle}  font-normal text-base   cursor-pointer`}>Pricing</a> 
                </Link>
                <Link to="/company">
                  <a
                    className={`${hoverStyle} text-base font-normal   cursor-pointer`}
                  >
                    Career
                  </a>
                </Link>
              </div>
            </div>
          
          
            {/* terms of service */}
            <div className="md:w-48">
              <h5 className={`${headerStyle}`}>Legal:</h5>
              <div className="grid gap-[0.5rem]">
                <Link to="/terms-of-service">
                <a className={`text-xs font-normal underline uppercase cursor-pointer ${hoverStyle}`}>Terms of Service</a></Link>
                <Link to="/privacy-policy">
                <a className={`${hoverStyle} text-xs font-normal underline uppercase cursor-pointer`}>Privacy Policy</a></Link>
              </div>
            </div>

              {/* mail */}
              <div className="">
              <h5 className={`${headerStyle}`}>Contact us:</h5>
              <div>
                <a className={`${contentStyle} ${hoverStyle}`} href="mailto:contact@obiclogistics.com">Obiclogistics@gmail.com</a>
                <br/>
                <a className={`${contentStyle} ${hoverStyle}`} href="tel:+2348135698995"><b>Booking line:</b> +2348135698995</a>
                <br/>
                <a className={`${contentStyle} ${hoverStyle}`} href="tel:+2348135698995"><b>Complaints & Enquiries:</b> +2348144197850, +2349075355144</a>
              </div>
            </div>

              {/* socials */}
              <div className="">
              <h5 className={`${headerStyle}`}>Socials:</h5>
              <div className="flex items-center flex-row gap-[.5rem] p-0">
                <a 
                aria-label="Learn more about cleva on Twitter page"
                  className={`${contentStyle} ${hoverStyle} `}
                  href="https://twitter.com/obic"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareTwitter className="text-2xl" />
                </a>
                <a
                  aria-label="Learn more about cleva on Instagram page"
                  className={`${contentStyle} ${hoverStyle} `}
                  href="https://www.instagram.com/obiclogistics?igsh=MTV5bWRlMjFwMnpmNg%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="text-2xl" />
                </a>

                <a
                  aria-label="Learn more about cleva on Instagram page"
                  className={`${contentStyle} ${hoverStyle} `}
                  href="https://www.tiktok.com/@obic_ng?_t=8pU2ww4m77t&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok className="text-2xl" />
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="border-t border-gray-700 pt-[2.37rem] text-center text-[#979797]">
          <p className="font-normal text-[0.875rem] pb-[2.31rem]">
            © {YEAR} Obic Logistics, Inc. All rights reserved
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
