import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';


const INTERCOM_APP_ID = 'm03zhbgi';
const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
    </IntercomProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
