
export const PricingHeader = () => {
    return (
      <section className="w-full relative">
           <div
          className={`bg-obic grid place-items-center text-center bg-black text-white p-[2rem] pt-[6rem] pb-[4.5rem]`}
        >
          <h1 className="text-[1.375rem] lg:text-[2rem] font-[700] leading-normal">
            Our Pricing
          </h1>
        </div>
      </section>
    );
  };
  