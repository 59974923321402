import { Link } from "react-router-dom";

function PartnerBanner() {
    return (
        <article className="rounded-xl bg-[#000000] relative mt-8 w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
            <div className="p-[0.5rem] md:p-4 lg:px-0 text-center">
                <p className="text-white text-base lg:text-xl font-medium">
                    <span>Partner with us and expand your business with Obic Logistics 🚚</span>
                    <Link to="/partnership" className="pl-[.75rem] text-[#1480E3] underline font-semibold cursor-pointer">Learn more here</Link>
                </p>
            </div>
        </article>
    );
}

export default PartnerBanner;
