import image1 from "../../images/team/1.jpeg"
import image2 from "../../images/team/2.jpeg"
import image3 from "../../images/team/3.jpeg"
export const CompanyMission = () => {
  return (
    <>
      <div className="grid gap-3 mt-10">
        <div className="md:w-[60%] mx-auto text-center">
          <h1 className="text-center uppercase font-bold text-3xl text-black">
            Our Vision
          </h1>
          <p className="md:text-center pt-3 leading-7">
            At Obic Logistics, our mission is to provide seamless, reliable, and
            efficient delivery services that empower businesses and individuals
            across Africa. We are committed to connecting people and businesses
            through fast, secure, and affordable logistics solutions. Whether
            it’s door-to-door services, corporate deliveries, or personal
            errands, we strive to deliver excellence with every package.
          </p>
        </div>

        <div className="photo-grid">
          <div className="photo">
            <img src={image1} alt="cleva" />
          </div>
          <div className="photo photo-2">
            <img src={image2} alt="cleva employee" />
          </div>
          <div className="grid gap-2">
            <div className="photo">
              <img src={image3} alt="cleva employee" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
