import Button from '../../components/Button';

const ServiceHeader = () => {

  return (
    <div className='mt-28'>
      <section className='bg-obic'>
        <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px] pt-8 pb-16">
          <div className="flex space-between lg:gap-16 items-center justify-center">
            <div className='hero-section'>
              <h1 className='text-white text-4xl font-semibold md:w-[20.51931rem] lg:w-[30.51931rem]'>
                Professional Delivery Services Tailored to Your Needs
              </h1>
              <p className='text-[#F1F1F1] text-xl py-8 font-normal md:w-[27.51931rem]'>
                From pickup to corporate deliveries, we offer a wide range of services to ensure your items are delivered promptly and securely.
              </p>
              <div className=''>
                <Button
                  text="Subscribe To Services"
                  fn={() => window.open("https://forms.gle/6CRL3DzcsUykLxuX7")}
                  style={`px-[2rem] text-[1.125rem] rounded-[0.75rem] bg-obic-blue text-white border-obic-blue `}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceHeader;
