import Accordion from "../../components/Accordion"
import {faq} from "./data"
import { useLocation } from "react-router-dom"
import {useEffect} from "react"
import DetailSection from "../home/Detail"

interface IFaq {
  innerRef?: React.RefObject<HTMLDivElement>
}

const FAQs = (props: IFaq) => {
  const {innerRef} = props
  const location = useLocation().pathname

  useEffect(() => {
    if (location === "/faq") {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
  }, [location])

  return (
    <>
      <section ref={innerRef} id="faq" className="">
        <div
          className={`bg-obic grid place-items-center text-center bg-black text-white p-[2rem] pt-[6rem] pb-[4.5rem]`}
        >
          <h1 className="text-[1.375rem] lg:text-[2rem] font-[700] leading-normal">
            Frequently Asked Questions
          </h1>
          <p className="pt-[.5rem] text-[0.875rem] lg:text-[1.25rem] font-normal leading-normal">
            Get answers to your questions about Obic logistics
          </p>
        </div>
        <div className="py-[2rem] md:py-[4.81rem] w-[80%] mx-auto 2xl:w-[1280px]">
          <div className="lg:w-[52.1875rem] lg:mx-auto">
            <Accordion data={faq} />
          </div>
        </div>
      </section>
      <DetailSection />
    </>
  )
}

export default FAQs
